import { Button } from '@/components/button';
import { registerSW } from 'virtual:pwa-register';
import Visibility from 'visibilityjs';
import { fiveMinutesMs, oneDayMs, oneHourMs } from './date';
import { setupRecentVisibleTabsDetection } from './open-tabs';
import { reportSentryWarning } from './sentry';
import { getNotificationIds, showNotification } from './show-notification';

//
// Register service worker & prompt user to refresh when new version is available
// To test locally: npm run build-dev && npm run preview
// To go back to HMR (npm run dev), unregister service worker manually
//

// For testing latest service worker in devtools (remember that code for page will be stale until refresh, even if new SW is activated)
// console.log('!!!!!!!!\n\n447\n\n\n');

const scriptLoadTime = Date.now();
const tabDetection = setupRecentVisibleTabsDetection();

// PWA Vite Plugin: https://vite-pwa-org.netlify.app/
// Call updateSW() to refresh all open tabs & start using new version
const updateSW = registerSW({
  onRegisterError(error) {
    reportSentryWarning('Service worker registration failed', { cause: error });
  },
  onNeedRefresh() {
    promptToRefreshIfFocused();
  },
  onRegisteredSW(swUrl, reg) {
    console.log('Service worker registered');
    if (!reg) return;

    const pollAndPrompt = () => {
      checkForNewVersion(swUrl, reg).then((isWaiting) => {
        if (isWaiting) promptToRefreshIfFocused();
      });
    };

    // Check right away
    pollAndPrompt();

    // How often to poll for new service worker (aka, new release)
    const whenVisibleInterval = fiveMinutesMs;
    const whenHiddenInterval = oneHourMs;
    Visibility.every(whenVisibleInterval, whenHiddenInterval, pollAndPrompt);
  },
});

// Prompt user to refresh so that page will be controlled by newly activated service worker
// Refresh automatically without prompting if:
// - User just navigated to page
// - Page is not focused and there's no form on the page
// - Don't refresh if there's a recently visible tab open in the browser, as any other tabs
//   will also be refreshed and the user could lose their progress
async function promptToRefreshIfFocused() {
  const isOtherTabRecentlyVisible = await tabDetection.isOtherTabRecentlyVisible(oneDayMs);
  const isOAuthCallbackPage = location.pathname === '/callback/';

  // If user just entered page, refresh unless on oAuth callback page or other tab was recently visible
  if (!isOtherTabRecentlyVisible && !isOAuthCallbackPage && Date.now() - scriptLoadTime < 200) {
    console.log('Refreshing automatically after new page load', Date.now() - scriptLoadTime);
    return updateSW();
  }

  const uniqueId = 'refresh_to_update';
  const notificationIds = await getNotificationIds();

  // Already showing notification, wait for user to accept/dismiss it
  if (notificationIds.includes(uniqueId)) return;

  // Automatically refresh if user isn't filling out form and page isn't focused
  if (!isOtherTabRecentlyVisible && !document.hasFocus() && !document.querySelector('form')) {
    console.log('Refreshing automatically when un-focused');
    return updateSW();
  }

  const message = isOtherTabRecentlyVisible
    ? 'Please save your work in any open tabs and then refresh to update.'
    : 'Please refresh this page to update.';
  const buttonText = isOtherTabRecentlyVisible ? 'Refresh All Tabs' : 'Refresh Now';
  showNotification({
    heading: 'A new version of this site is available!',
    message,
    children: (
      <Button onClick={() => updateSW()} variant="purple" className="mt-2 py-1">
        {buttonText}
      </Button>
    ),
    position: 'bottom-right',
    duration: 7 * 1000,
    uniqueId,
  });
}

// Poll for new version
// Source: https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
async function checkForNewVersion(swUrl: string, reg: ServiceWorkerRegistration) {
  if (!(!reg.installing && navigator)) return;

  if ('connection' in navigator && !navigator.onLine) return;

  console.log('Polling for new version...');

  let resp;
  try {
    resp = await fetch(swUrl, {
      cache: 'no-store',
      headers: {
        cache: 'no-store',
        'cache-control': 'no-cache',
      },
    });
  } catch (error) {
    console.warn('Service worker polling failed', error);
    return;
  }

  if (resp.status === 200) {
    await reg.update();
    // console.log([reg.installing, reg.waiting]);
  }

  return Boolean(reg.waiting);
}

// For debugging
if (typeof window !== 'undefined') {
  // @ts-ignore
  window.debugTabDetection = tabDetection;
}
