import { useEffect, useState } from 'react';

function useIsMobile(): boolean {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  useEffect(() => {
    const throttleTimeout = 100;
    let timeoutId: number | null = null;

    const checkIfMobile = () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = window.setTimeout(() => {
        setIsMobile(window.innerWidth < 768);
      }, throttleTimeout);
    };

    checkIfMobile();

    window.addEventListener('resize', checkIfMobile); // Add event listener for window resize

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId); // Cleanup timeout
      }
      window.removeEventListener('resize', checkIfMobile); // Cleanup event listener
    };
  }, []);

  return isMobile;
}

export default useIsMobile;
