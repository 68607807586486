import Visibility from 'visibilityjs';
import { z } from 'zod';
import { oneWeekMs } from './date';
import { reportSentryWarning } from './sentry';

const requestIdSchema = z
  .string()
  .min(12)
  .regex(/[a-z0-9]+-\d+/);

const requestSchema = z.object({
  message: z.literal('request_lastVisibleAt'),
  requestId: requestIdSchema,
});
type RequestMessage = z.infer<typeof requestSchema>;

const responseSchema = z.object({
  forRequestId: requestIdSchema,
  lastVisibleAt: z.number().gte(0),
});
type ResponseMessage = z.infer<typeof responseSchema>;

type DataFromOtherTabs = {
  requestId: string;
  timestamps: number[];
  latencies: number[];
  requestedAt: number;
  expiredAt: number | undefined;
};

export function setupRecentVisibleTabsDetection() {
  // Keep track of when this tab was last visible to the user, so that we can
  // share this info with other tabs
  let state = Visibility.state();
  let lastVisibleAt = state === 'visible' ? Date.now() : 0;
  Visibility.change((_, newState) => {
    if (state === 'visible' && newState === 'hidden') {
      lastVisibleAt = Date.now();
    }
    state = newState;
  });

  // Communicate with other tabs by one tab initiating a request and all
  // other tabs replying with a response of when they were last visible
  const tabId = randomId(10);

  // Utils for creating & validating messages
  const createRequestId = () => `${tabId}-${Date.now()}`;
  const createRequestMessage = (): RequestMessage => {
    return {
      message: 'request_lastVisibleAt',
      requestId: createRequestId(),
    };
  };
  const createResponseMessage = (forRequestId: string): ResponseMessage => {
    lastVisibleAt = state === 'visible' ? Date.now() : lastVisibleAt;
    return {
      forRequestId,
      lastVisibleAt,
    };
  };
  const isRequestMessage = (val: unknown): val is RequestMessage =>
    requestSchema.safeParse(val).success;
  const isResponseMessage = (val: unknown): val is ResponseMessage =>
    responseSchema.safeParse(val).success;

  // Keep track of what other tabs are visible
  let dataFromOtherTabs: DataFromOtherTabs | undefined;

  // Only wait so long to hear back from other tabs
  // Under 10ms when tab is visible, 20+ when browser is minimized (on a new Mac)
  const maxWaitTimeMs = 50;

  const channel = new BroadcastChannel('tab-visibility');
  channel.addEventListener('message', ({ data }) => {
    // Another tab is asking when this tab was last visible
    if (isRequestMessage(data)) {
      channel.postMessage(createResponseMessage(data.requestId));
      return;
    }

    // Another tab is letting this tab know when it was last visible
    if (isResponseMessage(data)) {
      if (dataFromOtherTabs && dataFromOtherTabs.requestId === data.forRequestId) {
        // Add lastVisibleAt timestamp from other tab
        dataFromOtherTabs.timestamps.push(data.lastVisibleAt);

        // For debugging latency to figure out ideal max wait time
        const latency = Date.now() - dataFromOtherTabs.requestedAt;
        dataFromOtherTabs.latencies.push(latency);
        // console.log('Response latency', latency);

        // Report messages in response to this tab that arrived too late to be counted
        // If we see this in Sentry, we should increase maxWaitTimeMs
        if (dataFromOtherTabs.expiredAt) {
          reportSentryWarning('Late tab-visibility data', {
            data,
            maxWaitTimeMs,
            overdueBy: Date.now() - dataFromOtherTabs.requestedAt - maxWaitTimeMs,
            dataFromOtherTabs,
          });
        }
      }
      return;
    }

    // Schema validation failed
    reportSentryWarning('Unexpected tab-visibility data', {
      data,
      request: requestSchema.safeParse(data),
      response: responseSchema.safeParse(data),
    });
  });

  // Wait until current request/response cycle completes before allowing
  // a new request/response cycle to be initiated. If called back-to-back,
  // the same in-flight Promise is returned.
  const getDataFromOtherTabs = reuseCurrentPromise(function (): Promise<DataFromOtherTabs> {
    return new Promise((resolve) => {
      // Check in with every tab and ask them to send us their last visible time
      const message = createRequestMessage();
      dataFromOtherTabs = {
        requestId: message.requestId,
        timestamps: [],
        latencies: [],
        requestedAt: Date.now(),
        expiredAt: undefined,
      };
      channel.postMessage(message);
      setTimeout(() => {
        // Done waiting, resolve with any tab responses that have come in so far
        dataFromOtherTabs!.expiredAt = Date.now();
        resolve(dataFromOtherTabs!);
      }, maxWaitTimeMs);
    });
  });

  // Expose relevant data here to client
  return {
    isOtherTabRecentlyVisible: async (maxTimeAgoMs?: number, debug = false) => {
      const data = await getDataFromOtherTabs();
      const recentlyVisibleTimestamps = data.timestamps.filter(
        (timestamp) => !isTooOld(timestamp, maxTimeAgoMs)
      );
      if (debug) console.log({ data, recentlyVisibleTimestamps });
      return recentlyVisibleTimestamps.length > 0;
    },
  };
}

//
// Utils
//

// Wrap a Promise-returning function such that it can never be
// executed in parallel. Means you can call the function 10x and
// it will returns the same promise every time, until the promise
// resolves or rejects.
function reuseCurrentPromise<T>(fn: () => Promise<T>): () => Promise<T> {
  let currentPromise: Promise<T> | undefined;

  return function (...args) {
    if (currentPromise) return currentPromise;

    currentPromise = fn(...args).then(
      (result) => {
        currentPromise = undefined;
        return result;
      },
      (error) => {
        currentPromise = undefined;
        throw error;
      }
    );

    return currentPromise;
  };
}

function isTooOld(timestamp: number, maxTimeAgoMs = oneWeekMs) {
  return Date.now() - timestamp > maxTimeAgoMs;
}

function randomId(length: number) {
  let result = '';
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}
