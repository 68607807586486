export function getPageTitle(pageContext: {
  exports: { documentProps?: { title: string } };
  documentProps?: { title: string };
}): string {
  const title = getPageExport(pageContext, 'title') || 'REX App';
  return `${title} | Redwood Materials`;
}

export function getPageExport<T>(
  pageContext: {
    exports: { documentProps?: any };
    documentProps?: any;
  },
  propertyName: string
): T {
  // For static titles (defined in the `export { documentProps }` of the page's `.page.js`)
  return (
    (pageContext.exports.documentProps || {})[propertyName] ||
    // For dynamic tiles (defined in the `export addContextProps()` of the page's `.page.server.js`)
    (pageContext.documentProps || {})[propertyName]
  );
}
