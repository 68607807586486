import { useIsUserStateStale } from '@/auth/user-store';
import { Card } from '@/components/card';
import { ForceUserRefreshDialog } from '@/components/force-user-refresh-dialog';
import Nav from '@/components/nav';
import { VpnPrompt } from '@/components/vpn-prompt';
import { pageContentClassName } from '@/utils/class-names';
import { isLocalDevelopment } from '@/utils/local-development';
import { ErrorBoundary } from '@sentry/react';
import React from 'react';
import { Toaster } from 'react-hot-toast';
import { SWRConfig } from 'swr';
import type { PageContext } from './types';
import { PageContextProvider } from './usePageContext';

export function PageShell({
  pageContext,
  children,
}: {
  pageContext: PageContext;
  children: React.ReactNode;
}) {
  const isUserStateStale = useIsUserStateStale();

  // Don't re-fetch API requests when user state is stale (e.g. they signed-out in different tab)
  const swrConfigValue = {
    revalidateOnFocus: !isUserStateStale,
    revalidateOnReconnect: !isUserStateStale,
  };
  return (
    <React.StrictMode>
      <PageContextProvider pageContext={pageContext}>
        <SWRConfig value={swrConfigValue}>
          <Nav />
          <ErrorBoundary
            // Sentry dialog will be shown unless it's blocked by browser extension or we're developing locally
            showDialog={!isLocalDevelopment()}
            fallback={
              <main className={pageContentClassName}>
                <Card heading="Unexpected Error">
                  <p className="mt-1">
                    Sorry, this page broke unexpectedly. Please refresh or contact support.
                  </p>
                </Card>
              </main>
            }
          >
            {children}
          </ErrorBoundary>
          <Toaster />
          <VpnPrompt />
          {isUserStateStale && <ForceUserRefreshDialog />}
        </SWRConfig>
      </PageContextProvider>
    </React.StrictMode>
  );
}
