import logoUrl from '@/assets/logo-light.png';
import rexLogoUrl from '@/assets/rex-logo-designer.svg';
import { useUser } from '@/auth/user-store';
import useIsMobile from '@/hooks/useIsMobile';
import { usePageContext } from '@/renderer/usePageContext';
import { classNames } from '@/utils/class-names';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, ChevronDownIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useEffect, useRef, useState } from 'react';

type NavigationItem = {
  name: string;
  href: string;
  current: boolean;
  children?: NavigationItem[];
};

type NavigationData = NavigationItem[];

export default function Nav() {
  const { isSignedIn, user: { firstName, lastName, email } = {}, signOut } = useUser();
  const { urlPathname } = usePageContext();
  const isMobile = useIsMobile();

  // State to track the index of the currently open nav group
  const [openGroupIndex, setOpenGroupIndex] = useState<number | null>(null);
  const groupRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Add navigation links and optional children dropdown menus - populates both desktop and mobile navigation
  const urlPathNameStartsWith = (path: string) => urlPathname.startsWith(path);
  const navigationData: NavigationData = [
    {
      name: 'Material Flow Forms',
      href: '#',
      // Set current active nav item on page load
      current: urlPathNameStartsWith('/ig-fg-form') || urlPathNameStartsWith('/receiving-form'),
      children: [
        {
          name: 'Receiving Form',
          href: '/receiving-form/',
          current: urlPathNameStartsWith('/receiving-form'),
        },
        {
          name: 'IG/FG Form',
          href: '/ig-fg-form/',
          current: urlPathNameStartsWith('/ig-fg-form'),
        },
      ],
    },
    {
      name: 'Image Search',
      href: '/image-search/',
      current: urlPathNameStartsWith('/image-search'),
    },

    {
      name: 'Quality Control',
      href: '#',
      // Set current active nav item on page load
      current:
        urlPathNameStartsWith('/manual-inspection-form') || urlPathNameStartsWith('/lpn-status'),
      children: [
        {
          name: 'Manual Inspection',
          href: '/manual-inspection-form/',
          current: urlPathNameStartsWith('/manual-inspection-form'),
        },
        {
          name: 'LPN Status',
          href: '/lpn-status/',
          current: urlPathNameStartsWith('/lpn-status'),
        },
        {
          name: 'ICP Upload',
          href: '/icp-upload/',
          current: urlPathNameStartsWith('/icp-upload'),
        },
      ],
    },

    {
      name: 'Inventory Tasks',
      href: '#',
      // Set current active nav item on page load
      current:
        urlPathNameStartsWith('/cycle-counting-assignment') ||
        urlPathNameStartsWith('/cycle-counting-tasks') ||
        urlPathNameStartsWith('/cycle-counting-problem-tasks'),
      children: [
        {
          name: 'Count Task Assignment',
          href: '/cycle-counting-assignment/',
          current: urlPathNameStartsWith('/cycle-counting-assignment'),
        },
        {
          name: 'Count Tasks',
          href: '/cycle-counting-tasks/',
          current: urlPathNameStartsWith('/cycle-counting-tasks/'),
        },
        {
          name: 'Problem Tasks',
          href: '/cycle-counting-problem-tasks/',
          current: urlPathNameStartsWith('/cycle-counting-problem-tasks/'),
        },
      ],
    },
  ];

  const navigation = navigationData.map((group) => {
    // Determine if the group or any of its children matches the current URL path
    const isCurrentGroup = urlPathNameStartsWith(group.href);
    const selectedChild = group.children?.find((child) => urlPathNameStartsWith(child.href));
    const isCurrentChild = !!selectedChild;

    return {
      ...group,
      // Update group's name to the selected child's name if in desktop view and a child is selected
      name: selectedChild && !isMobile ? selectedChild.name : group.name,
      // The group is current if it's explicitly matched or if any of its children are current
      current: group.current || isCurrentGroup || isCurrentChild,
      // Update children to reflect current state based on the URL path
      children: group.children?.map((item) => ({
        ...item,
        current: urlPathNameStartsWith(item.href),
      })),
    };
  });

  useEffect(() => {
    function handleOutsideClick(event: MouseEvent) {
      // Ensure the target is an Element for the contains check
      const target = event.target as Element;
      // Check if the current open group's ref contains the event target
      if (
        openGroupIndex !== null &&
        groupRefs.current[openGroupIndex] &&
        !groupRefs.current[openGroupIndex]?.contains(target)
      ) {
        setOpenGroupIndex(null); // Close the open groups if click is outside
      }
    }
    // Add event listener if a group is open
    if (openGroupIndex !== null) {
      document.addEventListener('pointerdown', handleOutsideClick);
    }
    return () => document.removeEventListener('pointerdown', handleOutsideClick);
  }, [openGroupIndex]);

  // Toggle the open state of a group
  const toggleGroupOpen = (index: number | null) => {
    setOpenGroupIndex(index);
  };

  const userNavigation = [
    // { name: 'Your Profile', href: '#' },
    { name: 'Settings', href: '#' },
    { name: 'Sign out', onClick: signOut },
  ];

  const charsInCircle = (
    firstName && lastName
      ? `${firstName.charAt(0)}${lastName.charAt(0)}`
      : (email || '?').slice(0, 2)
  ).toUpperCase();

  return isSignedIn ? (
    <>
      <Disclosure as="nav" className="z-30 bg-rw-blue-2">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <a className="flex items-center" href="/">
                      <img className="h-7" src={logoUrl} alt="Redwood Materials" width={110} />
                      <img className="h-10" src={rexLogoUrl} alt="REX Logo" width={40} />
                    </a>
                  </div>
                  {!isMobile && (
                    <div className="hidden md:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((group, groupIndex) => (
                          <div
                            className="relative ml-3"
                            key={group.name}
                            ref={(el) => (groupRefs.current[groupIndex] = el)}
                          >
                            {group.children ? (
                              <a
                                href={group.href}
                                key={group.name}
                                onClick={() => toggleGroupOpen(groupIndex)}
                                className={classNames(
                                  'flex',
                                  group.current
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'rounded-md px-3 py-2 text-sm font-medium'
                                )}
                              >
                                {group.name}
                                {openGroupIndex === groupIndex ? (
                                  <ChevronUpIcon className="ml-2 h-5 w-5" />
                                ) : (
                                  <ChevronDownIcon className="ml-2 h-5 w-5" />
                                )}
                              </a>
                            ) : (
                              <a
                                key={group.name}
                                href={group.href}
                                className={classNames(
                                  group.current
                                    ? 'bg-gray-900 text-white'
                                    : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                  'rounded-md px-3 py-2 text-sm font-medium'
                                )}
                                aria-current={group.current ? 'page' : undefined}
                              >
                                {group.name}
                              </a>
                            )}

                            {/* Desktop dropdown menus */}
                            {openGroupIndex === groupIndex && (
                              <div className="absolute left-0 z-30 mt-1 w-48 origin-top-left rounded-md bg-rw-blue-2 shadow-inner ring-1 ring-black ring-opacity-20 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                {group.children &&
                                  group.children.map((item, i) => (
                                    <a
                                      key={item.name}
                                      href={item.href}
                                      onClick={() => toggleGroupOpen(null)}
                                      className={classNames(
                                        'block w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-800 hover:text-white',
                                        item.current && 'bg-gray-900', // Apply a different background for the current item
                                        i === 0 && 'rounded-tl-md rounded-tr-md', // Apply top border-radius to the first item
                                        group.children &&
                                          i === group?.children.length - 1 &&
                                          'rounded-bl-md rounded-br-md' // Apply bottom border-radius to the last item
                                      )}
                                      aria-current={group.current ? 'page' : undefined}
                                    >
                                      {item.name}
                                    </a>
                                  ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {isSignedIn && (
                  <div className="hidden md:block">
                    <div className="ml-4 flex items-center md:ml-6">
                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex h-8 w-8 items-center justify-center rounded-full bg-green-700 shadow-inner focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                            <div className="text-sm font-bold text-white">{charsInCircle}</div>
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-30 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="block w-full px-4 py-2 text-left text-xs text-gray-700">
                              {firstName && lastName && (
                                <div>
                                  {firstName} {lastName}
                                </div>
                              )}
                              {email && <div className="truncate">{email}</div>}
                            </div>
                            <hr />
                            {userNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => {
                                  const className = classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                  );

                                  return item.onClick ? (
                                    <button onClick={item.onClick} className={className}>
                                      {item.name}
                                    </button>
                                  ) : (
                                    <a href={item.href} className={className}>
                                      {item.name}
                                    </a>
                                  );
                                }}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                )}

                {/* Mobile Markup */}
                {isMobile && (
                  <div className="-mr-2 flex md:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                )}
              </div>
            </div>

            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                {navigation.map((group, groupIndex) => (
                  <div key={group.name} className="relative">
                    {group.children ? (
                      <button
                        onClick={() => toggleGroupOpen(groupIndex)}
                        className={classNames(
                          'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'flex items-center rounded-md px-3 py-2 text-base font-medium'
                        )}
                      >
                        {group.name}
                        {openGroupIndex === groupIndex ? (
                          <ChevronUpIcon className="ml-2 h-5 w-5" />
                        ) : (
                          <ChevronDownIcon className="ml-2 h-5 w-5" />
                        )}
                      </button>
                    ) : (
                      <Disclosure.Button
                        key={group.name}
                        onClick={() => setOpenGroupIndex(null)}
                        as="a"
                        href={group.href}
                        className={classNames(
                          group.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block rounded-md px-3 py-2 text-base font-medium'
                        )}
                        aria-current={group.current ? 'page' : undefined}
                      >
                        {group.name}
                      </Disclosure.Button>
                    )}

                    {/* Mobile Drop down menus */}
                    {openGroupIndex === groupIndex && (
                      <>
                        {group.children &&
                          group.children.map((item) => (
                            <Disclosure.Button
                              key={item.name}
                              as="a"
                              href={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'ml-6 block rounded-md px-3 py-2 text-base font-medium'
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </Disclosure.Button>
                          ))}
                      </>
                    )}
                  </div>
                ))}
              </div>
              {isSignedIn && (
                <div className="border-t border-gray-700 pb-3 pt-4">
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <span className="flex h-9 w-9 items-center justify-center rounded-full bg-green-700 shadow-inner focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <div className="text-md font-bold text-white">{charsInCircle}</div>
                      </span>
                    </div>
                    <div className="ml-3">
                      <div className="text-sm leading-4 text-white">
                        {firstName && lastName && (
                          <div>
                            {firstName} {lastName}
                          </div>
                        )}
                        {email && <div className="truncate">{email}</div>}
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    {userNavigation.map((item) =>
                      item.onClick ? (
                        <Disclosure.Button
                          key={item.name}
                          as="button"
                          onClick={item.onClick}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ) : (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      )
                    )}
                  </div>
                </div>
              )}
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>

      {import.meta.env.MODE !== 'production' && (
        <p className="fixed bottom-0 left-0 z-50 rounded-md bg-yellow-200 px-2 py-1">
          {import.meta.env.MODE}
        </p>
      )}
    </>
  ) : null;
}
