// /renderer/_default.page.client.js
// Environment: Browser

// Source: https://github.com/vikejs/vike/blob/main/examples/react-full/renderer/_default.page.client.tsx

export { onHydrationEnd, render };
// export { onPageTransitionStart };
// export { onPageTransitionEnd };

// Enable Client Routing
export const clientRouting = true;

// Whether your UI framework allows the hydration to be aborted. (Allowing Vike
// to abort the hydration if the user clicks on a link before the hydration finished.)
// Only React users should set `hydrationCanBeAborted` to `true`. (Other frameworks,
// such as Vue, throw an error if the hydration is aborted.)
export const hydrationCanBeAborted = true;
import ReactDOM from 'react-dom/client';
import '../index.scss';
import '../utils/pwa';
import '../utils/sentry';
import { PageShell } from './PageShell';
import { getPageTitle } from './getPageTitle';
import type { PageContextClient } from './types';

let root: ReactDOM.Root;
async function render(pageContext: PageContextClient) {
  const { Page, pageProps } = pageContext;
  const page = (
    <PageShell pageContext={pageContext}>
      <Page {...pageProps} />
    </PageShell>
  );
  const container = document.getElementById('react-root')!;
  // TEMP: Skip hydration to workaround data being stored locally and initial
  // render deferring from server.
  // if (pageContext.isHydration) console.log('Skipping hydration');
  // if (pageContext.isHydration) {
  //   console.log('hydrating...', container.innerHTML.slice(0, 100));
  //   root = ReactDOM.hydrateRoot(container, page);
  // } else {
  if (!root) {
    // console.log('create root...');
    root = ReactDOM.createRoot(container);
  }
  root.render(page);
  // }
  document.title = getPageTitle(pageContext);
}

function onHydrationEnd() {
  // console.log('Hydration finished; page is now interactive.');
}

// function onPageTransitionStart(pageContext: PageContextClient) {
//   console.log('Page transition start');
//   // `pageContext.isBackwardNavigation` is also set at `render(pageContext)`
//   // and `onPageTransitionEnd(pageContext)`.
//   console.log('Is backwards navigation?', pageContext.isBackwardNavigation);
//   document.querySelector('body')!.classList.add('page-is-transitioning');
// }

// function onPageTransitionEnd(pageContext: PageContextClient) {
//   console.log('Page transition end');
//   document.querySelector('body')!.classList.remove('page-is-transitioning');
// }
