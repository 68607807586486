import { checkVpnConnection } from '@/api/endpoints/vpn-connection';
import { fiveMinutesMs } from '@/utils/date';
import { showNotification } from '@/utils/show-notification';
import { Fragment, useEffect, useRef } from 'react';
import useSWR from 'swr';

//
// Check if user is not connected to VPN and needs to be for this environment
// If so, prompt them to reconnect
//

export function VpnPrompt() {
  const { error } = useSWR('check-vpn-connection', checkVpnConnection);
  const needsToConnect = Boolean(error);
  const dismissRef = useRef<(() => void) | null>(null);
  const lastDismissedTime = useRef(0);

  useEffect(() => {
    if (needsToConnect && Date.now() - lastDismissedTime.current > fiveMinutesMs) {
      dismissRef.current = showNotification({
        heading: 'Please connect to Redwood Network',
        message: 'Checking connectivity...',
        type: 'error',
        duration: Infinity,
        uniqueId: 'vpn_connection',
        onManuallyDismiss: () => {
          lastDismissedTime.current = Date.now();
        },
      });
    } else if (!needsToConnect && dismissRef.current) {
      dismissRef.current();
      dismissRef.current = null;
      showNotification({
        heading: 'Successfully connected!',
        duration: 1500,
      });
    }
  }, [needsToConnect, error]);

  return <Fragment />;
}
